import {callAPI} from "../network/base/NetworkInterface";
import {HTTP_METHOD} from "../constants/Constants"
import getProblemSolveCategoryMap from "../json/getProblemSolveCategoryMap";
import getReverseProblemSolveCategoryMap from "../json/getReverseProblemSolveCategoryMap";
import getPharmacyProblemSolveCategoryMap from "../json/getPharmacyProblemSolveCategoryMap";
import getPharmacyReverseProblemSolveCategoryMap from "../json/getPharmacyReverseProblemSolveCategoryMap";
import getSwaProblemSolveCategoryMap from "../json/getSWAProblemSolveCategoryMap";
import qs from "qs";
import {FeatureManager, Logger} from "@amzn/dolphin-web-framework";
import {MIDDLE_MILE, PHARMACY_SHIPMENT_CATEGORY, SWA_SHIPMENT_CATEGORY} from "../constants/Constants"

const ProblemSolveServiceEndpoint = "/ps/station-op/problemsolve/";

const ENDPOINTS = {
    getCategoriesForNodeId:"getCategoriesForNodeId",
    getExceptionShipmentSummaryDetails: "getExceptionShipmentSummaryDetails",
    getFeatureValue: "getFeatureValue",
    getPackageLabel: "getPackageLabel",
    getStationList: "getStationList",
    getPackageAttribute: "getPackageAttribute",
    updateExceptionShipments: "updateExceptionShipments",
    getProblemSolveCategoryMap: "getProblemSolveCategoryMap",
    getShipmentsByScannableId: "getShipmentsByScannableId",
    searchTransporter: "searchTransporter",
    updateExceptionShipmentsForMultipleProblemCategory: "updateExceptionShipmentsForMultipleProblemCategory",
    unassignRoute: "unassignRoute",
    getStagingAreaForPickList: "getStagingAreaForPickList",
    getExceptionShipmentSummary: "getExceptionShipmentSummary",
    getExceptionShipmentSummaryWithoutShipDetails: "getExceptionShipmentSummaryWithoutShipDetails",
    scrubPackage: "scrubPackage",
    updateExceptionTransporter: "updateExceptionTransporter"
};

export const NPSWClient = {
    getCategoriesForNodeId: async (args) => {
        Logger.log.info("Request: getCategoriesForNodeId, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint: getEndPoint(ENDPOINTS.getCategoriesForNodeId)
        });
    },

    getProblemSolveCategoryMap: async (args) => {
        Logger.log.info("Request: getProblemSolveCategoryMapAPI, Args: ", args);
        // return await callAPI({
        //     method: HTTP_METHOD.GET,
        //     endpoint: ENDPOINTS.getProblemSolveCategoryMapAPI
        // });
        return new Promise( (resolve) => {
            let categoryMap = {};

            if (args?.destinationDomain === MIDDLE_MILE) {
                if (args?.shipmentCategory === PHARMACY_SHIPMENT_CATEGORY) {
                    categoryMap = getPharmacyReverseProblemSolveCategoryMap;
                } else {
                    categoryMap = getReverseProblemSolveCategoryMap;
                }
            } else {
                if (args?.shipmentCategory === PHARMACY_SHIPMENT_CATEGORY) {
                    categoryMap = getPharmacyProblemSolveCategoryMap;
                } else if (args?.shipmentCategory === SWA_SHIPMENT_CATEGORY
                    && FeatureManager.isFeatureEnabled(FeatureManager.Features.SWA_UNDELIVERABLE_CATEGORY_DISABLED)) {
                    categoryMap = getSwaProblemSolveCategoryMap;
                } else {
                    categoryMap = getProblemSolveCategoryMap;
                }
            }

            setTimeout(() => {
                resolve({
                    success: true,
                    data: categoryMap
                })
            },500)
        })
    },

    getExceptionShipmentSummaryDetails: async (args) => {
        Logger.log.info("Request: getExceptionShipmentSummaryDetails, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint: getEndPoint(ENDPOINTS.getExceptionShipmentSummaryDetails),
            data: args
        });
    },

    getExceptionShipmentSummaryWithoutShipDetails: async (args) => {
        Logger.log.info("Request: getExceptionShipmentSummaryWithoutShipDetails, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint:  getEndPoint(ENDPOINTS.getExceptionShipmentSummaryWithoutShipDetails),
            data: args
        });
    },

    getExceptionShipmentSummary: async (args) => {
        Logger.log.info("Request: getExceptionShipmentSummary, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint:  getEndPoint(ENDPOINTS.getExceptionShipmentSummary),
            data: args
        });
    },

    scrubPackage: async (args) => {
        Logger.log.info("Request: scrubPackage, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint:  getEndPoint(ENDPOINTS.scrubPackage),
            data: qs.stringify(args)
        });
    },

    getFeatureValue: async (args) => {
        Logger.log.info("Request: getFeatureValue, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint: getEndPoint(ENDPOINTS.getFeatureValue),
            data: args
        });
    },

    getPackageLabel: async (args) => {
        Logger.log.info("Request: getPackageLabel, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint: getEndPoint(ENDPOINTS.getPackageLabel),
            data: args
        })
    },

    getStationList: async () => {
        Logger.log.info("Request: getStationList");
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint: getEndPoint(ENDPOINTS.getStationList),
        })
    },

    getPackageAttribute: async (args) => {
        Logger.log.info("Request: getPackageAttribute, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint: getEndPoint(ENDPOINTS.getPackageAttribute),
            data: args
        })
    },

    updateExceptionShipments: async (args, meta, exceptionScannableIdMetaData) => {
        Logger.log.info("Request: updateExceptionShipments, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.updateExceptionShipments),
            data: qs.stringify(args,  { arrayFormat: 'brackets'})
              +"&shipmentMetaData="+ encodeURIComponent(JSON.stringify(meta))
              +"&exceptionScannableIdMetaData="+ encodeURIComponent(JSON.stringify(exceptionScannableIdMetaData))
        })
    },

    updateExceptionTransporter: async (args, overrideReason) => {
        Logger.log.info("Request: updateExceptionTransporter, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.updateExceptionTransporter),
            data: qs.stringify(args,  { arrayFormat: 'brackets'})
            +"&overrideInfo=UpdateTransporterOverrideInfo(overrideReason="+ overrideReason
            +")"
        })
    },

    getShipmentsByScannableId: async (args) => {
        Logger.log.info("Request: getShipmentsByScannableId, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint: getEndPoint(ENDPOINTS.getShipmentsByScannableId),
            data: args
        });
    },

    searchTransporter: async (args) => {
        Logger.log.info("Request: searchTransporter, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.searchTransporter),
            data: qs.stringify(args,  { arrayFormat: 'brackets'})
        })
    },

    updateExceptionShipmentsForMultipleProblemCategory: async (args) => {
        Logger.log.info("Request: updateExceptionShipmentsForMultipleProblemCategory", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.updateExceptionShipmentsForMultipleProblemCategory),
            data: args
        })
    },

    unassignRoute: async (args) => {
        Logger.log.info("Request: Unassign Route, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.POST,
            endpoint: getEndPoint(ENDPOINTS.unassignRoute),
            data: args
        })
    },

    getStagingAreaForPickList: async (args) => {
        Logger.log.info("Request: getStagingAreaForPickList, Args: ", args);
        return await callAPI({
            method: HTTP_METHOD.GET,
            endpoint: getEndPoint(ENDPOINTS.getStagingAreaForPickList),
            data: args
        });
    },
};

const getEndPoint = (serviceEndpoint) => {
    return ProblemSolveServiceEndpoint + serviceEndpoint
}
