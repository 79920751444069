import {APIEvents} from "../../../constants/AnalyticsEventKeys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {getNNSClient} from "../../base/ClientInterface";

export function GetPackageAttributeAPIV2(data, dispatcher, nextAction, errorAction) {
    let startTime = Date.now();
    let asinCodeList = {
        "asinCodeList" : data.asinCode
    }
    getNNSClient().getPackageAttribute(asinCodeList)
        .then(({success, data}) => {
            if (success) {
                console.log(data);
                dispatcher({
                    type: nextAction,
                    data: data
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetPackageAttributes, startTime);
            } else {
                dispatcher({
                    type: errorAction
                });
                MobileAnalyticsHelper.executeAPIAnalytics(APIEvents.GetPackageAttributes, startTime, true);
            }
        });
}
